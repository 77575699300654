<template>
  <v-card flat tile class="charcoal">
    <v-sheet
      color="transparent"
      class="pa-2 mx-0 mt-2"
      :style="charcoalTileMenu"
    >
      <v-card-title class=" paper--text text-h4 pa-2 pl-0 rounded">
        VOLUME
        <span
          v-if="what.label == null || what.label == 'Overall'"
          class="caption pl-1 mt-8 silver--text"
        >
          Overall
        </span>
        <v-spacer />
        <v-switch
          dark
          color="progressActive "
          title="Fine Scale?"
          v-model="finescale"
          @click="setScale"
        >
          <template v-slot:label>
            <span class="silver--text">Fine Scale?</span>
          </template>
        </v-switch>
      </v-card-title>
      <v-card-actions
        v-if="what.label != null && what.label != 'Overall'"
        class="pa-0"
      >
        <span :class="textSizeSmall + '  silver--text'">
          {{ what.label }}
        </span>
      </v-card-actions>
      <v-card-actions
        v-if="max > 0"
        :class="textSizeXSmall + ' paper--text px-0 py-0'"
      >
        <span class="pinkAccent--text mr-1"> Min: {{ min }} </span>
        <span class="blueAccent--text mr-1"> Max: {{ max }} </span>
        <span class="orange--text"> Avg: {{ avg }} </span>
        <v-spacer />
      </v-card-actions>
      <v-card-title v-else :class="textSizeXSmall + ' silver--text py-0'">
        No Data Available.
      </v-card-title>
      <v-select
        solo
        height="60"
        dense
        color="nicegreen"
        item-color="black"
        background-color="charcoal lighten-2"
        dark
        v-model="groupby"
        return-object
        prepend-inner-icon="mdi-calendar"
        :items="interval"
        label="Interval"
        item-text="text"
        item-value="value"
        @change="loadItems"
        :menu-props="{
          closeOnClick: true,
          maxHeight: 280,
          transition: 'fab-transition'
        }"
      />
      <MyLineChart
        class="ml-n2"
        v-if="loaded"
        :chartdata="chartdata"
        :options="options"
        :key="switchnum"
      />
    </v-sheet>
  </v-card>
</template>

<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const MyLineChart = () =>
  import(/* webpackPrefetch: true */ '@/components/charts/MyLineChart.vue')
export default {
  components: { MyLineChart },
  mixins: [util],
  data: () => ({
    chartdata: {},
    options: {},
    items: [],
    weights: [],
    values: [],
    labels: [],
    loaded: false,
    min: 0,
    max: 200,
    avg: 0,
    offsset: 10,
    switchnum: 0,
    stepsize: 50,
    finescale: false,
    groupby: { text: 'By Month', value: 'monthname' },
    interval: [
      { text: 'By Month', value: 'monthname' },
      { text: 'By Week', value: 'week' },
      { text: 'By Time Of Day', value: 'timeofday' },
      { text: 'By Hour', value: 'hour' },
      { text: 'Day to Day', value: 'day' }
    ]
  }),
  props: {
    mode: {
      type: String,
      default: 'overall'
    },
    what: {
      type: Object
    },
    selection: {
      type: Object
    },
    metric: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.selection) this.groupby = this.selection
    this.loadItems()
  },
  validations: {},
  computed: {
    ...appConfig,
    ...builder
  },
  watch: {
    metric: function() {
      this.loadItems()
    },
    finescale: function() {
      this.loadItems()
    }
  },
  methods: {
    setScale() {
      this.stepsize = this.finescale ? 20 : 50
    },
    loadItems() {
      {
        this.$emit('group_by', this.groupby)
        let liftingBase = '/stats/lifting/volume/'
        let url = this.baseURL + liftingBase + this.groupby.value + ':all'

        if (
          this.mode == 'bymuscle' ||
          this.mode == 'byexercise' ||
          this.mode == 'byworkout'
        )
          url =
            this.baseURL +
            liftingBase +
            this.mode +
            '/' +
            this.groupby.value +
            ':' +
            this.what.id
        if (this.mode == 'bymusclegroup' || this.mode == 'bybodyposition')
          url =
            this.baseURL +
            liftingBase +
            this.mode +
            '/' +
            this.groupby.value +
            ':' +
            this.what.label

        return axios.get(url, {}).then(response => {
          if (response.status == 200) {
            this.items = response.data.data

            if (this.metric) {
              this.values = this.items.map(function(obj) {
                return Math.floor(parseFloat(obj.metric) * 100) / 100
              })
            } else {
              this.values = this.items.map(function(obj) {
                return Math.floor(parseFloat(obj.imperial) * 100) / 100
              })
            }
            this.labels = this.items.map(function(obj) {
              return obj.label
            })
            this.min = this.arrayMin(this.values)
            this.max = this.arrayMax(this.values)
            this.avg = this.arrayAvg(this.values)

            this.chartdata = {
              labels: this.labels,
              datasets: [
                {
                  label:
                    this.groupby.text +
                    (this.what.label ? ', ' + this.what.label : ''),
                  backgroundColor: '#fafafa',
                  borderColor: '#53a653',
                  borderWidth: 1,
                  data: this.values,
                  radius: 4,
                  pointHoverRadius: 15,
                  pointHoverBorderWidth: 10,
                  pointHoverBorderColor: '#212b31',
                  fill: false,
                  pointBackgroundColor: '#53a653',
                  lineTension: 0.1,
                  showLines: true
                },
                {
                  label: 'Avg.',
                  data: Array.apply(null, new Array(this.values.length)).map(
                    Number.prototype.valueOf,
                    this.avg
                  ),
                  fill: false,
                  radius: 0,
                  borderColor: 'orange',
                  borderWidth: 1,
                  border: '1'
                },
                {
                  label: 'Max',
                  data: Array.apply(null, new Array(this.values.length)).map(
                    Number.prototype.valueOf,
                    this.max
                  ),
                  fill: false,
                  radius: 0,
                  borderColor: '#b0e8ff',
                  borderWidth: 1,
                  border: '1'
                },
                {
                  label: 'Min',
                  data: Array.apply(null, new Array(this.values.length)).map(
                    Number.prototype.valueOf,
                    this.min
                  ),
                  fill: false,
                  radius: 0,
                  borderColor: '#ff63c5',
                  borderWidth: 1,
                  border: '1'
                }
              ]
            }
            this.options = {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                labels: {
                  fontColor: '#9e9e9e',
                  boxWidth: 2,
                  usePointStyle: false
                }
              },
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      zeroLineColor: '#9e9e9e',
                      color: '#444'
                    },
                    ticks: {
                      stepSize: this.stepsize,
                      fontColor: '#efefef',
                      tickColor: '#9e9e9e'
                    }
                  }
                ],
                yAxes: [
                  {
                    suggestedMin: Math.max(this.min - this.offsset, 0),
                    suggestedMax: this.max + this.offsset,
                    gridLines: {
                      zeroLineColor: '#9e9e9e',
                      color: '#444'
                    },
                    ticks: {
                      stepSize: this.stepsize,
                      fontColor: '#efefef',
                      tickColor: '#9e9e9e',
                      callback: function(label) {
                        return label
                      }
                    }
                  }
                ]
              }
            }
            this.$emit('get_max', this.max)
            this.loaded = true
            this.switchnum++
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
      }
    }
  }
}
</script>
